import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';

import AuthRoutes from './auth';
import BlogRoutes from './blog-routes';
import HrRoutes from './hr-routes';
import MarketplaceRoutes from './marketplace-routes';

const IndexPage = lazy(() => import('src/pages/dashboard/index'));
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
const Blank = lazy(() => import('src/pages/blank'));
const Error404Page = lazy(() => import('src/pages/404'));

export const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'analytics',
        // element: <AnalyticsPage />
        element: <Blank />
      },
      {
        path: 'account',
        element: <AccountPage />
      },
      {
        path: 'settings',
        element: <AccountPage />
      },
      {
        path: 'knowledgebase',
        element: <Blank />
      },
      {
        path: 'blank',
        element: <Blank />
      },
      ...BlogRoutes,
      ...HrRoutes,
      ...MarketplaceRoutes,
    ]
  },
  ...AuthRoutes,
  {
    path: '*',
    element: <Error404Page />
  }
];
