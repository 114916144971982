export const tokens = {
  morning: {
    headline: "morning.headline",
    text: "morning.text",
  },
  lunch: {
    headline: "lunch.headline",
    text: "lunch.text",
  },
  afternoon: {
    headline: "afternoon.headline",
    text: "afternoon.text",
  },
  evening: {
    headline: "evening.headline",
    text: "evening.text",
  },
  night: {
    headline: "night.headline",
    text: "night.text",
  },
};

export const de = {
  [tokens.morning.headline]: "Guten Morgen, Fruchtweinmacher/In {{name}}",
  [tokens.morning.text]: "Hab einen guten Start in den Tag 😊",
  [tokens.lunch.headline]: "Maaaaahlzeit, Fruchtweinmacher/In {{name}}.",
  [tokens.lunch.text]: "Schon Mittag, die Zeit rast 😊",
  [tokens.afternoon.headline]: "Hallihallo, Fruchtweinmacher/In {{name}.}",
  [tokens.afternoon.text]: "Es ist soweit, Kaffeezeit 😊",
  [tokens.evening.headline]: "Schönen Abend, Fruchtweinmacher/In {{name}}.",
  [tokens.evening.text]: "Lass‘ es dir gut gehen 😊",
  [tokens.night.headline]: "Gute Nacht, Fruchtweinmacher/In {{name}}.",
  [tokens.night.text]: "So spät noch wach? Dann jetzt aber hopp 😊",
};

export const en = {
  [tokens.morning.headline]: "Good morning, Fruchtweinmacher/In {{name}}",
  [tokens.morning.text]: "Hab einen guten Start in den Tag 😊",
  [tokens.lunch.headline]: "Enjoy your lunch, Fruchtweinmacher/In {{name}}.",
  [tokens.lunch.text]: "Schon Mittag, die Zeit rast 😊",
  [tokens.afternoon.headline]: "Coffee time! Fruchtweinmacher/In {{name}.}",
  [tokens.afternoon.text]: "Es ist soweit, Kaffeezeit 😊",
  [tokens.evening.headline]: "Good evening, Fruchtweinmacher/In {{name}}.",
  [tokens.evening.text]: "Lass‘ es dir gut gehen 😊",
  [tokens.night.headline]: "Good night, Fruchtweinmacher/In {{name}}.",
  [tokens.night.text]: "So spät noch wach? Dann jetzt aber hopp 😊",
};
