import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";

interface LayoutProps {
  children: ReactNode;
  background?: string;
}

export const Layout: FC<LayoutProps> = (props) => {
  const {
    children,
    background = "/images/backgrounds/daniel-schludi-Ue1sGyjLUME-unsplash.jpg",
  } = props;

  return (
    <Box
      sx={{
        backgroundImage: "background.default",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "neutral.800",
          backgroundImage: `url("${background}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "common.white",
          display: "flex",
          flex: {
            xs: "0 0 auto",
            md: "1 1 auto",
          },
          justifyContent: "center",
          p: {
            xs: 4,
            md: 8,
          },
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          flex: {
            xs: "1 1 auto",
            md: "0 0 auto",
          },
          flexDirection: "column",
          justifyContent: {
            md: "center",
          },
          maxWidth: "100%",
          p: {
            xs: 4,
            md: 8,
          },
          width: {
            md: 600,
          },
        }}
      >
        <div>
          <Box sx={{ mb: 4 }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box maxWidth="md">
                <Typography sx={{ mb: 1 }} variant="h4">
                  Welcome to Katlenburger Katnet
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 4 }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  ut est aliquam, lobortis elit at, blandit metus. Vivamus
                  tellus justo, varius vitae aliquam at, porttitor eu risus.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </div>
        {children}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
