import { createSelector } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { mastodonConfig } from "src/config";
import {
  getLocalStorage,
  setLocalStorage,
} from "src/services/localStorage.service";
import { Post } from "src/types/posts";
import { LoggedUser, User, UserField } from "src/types/user";
import { Context } from "vm";

export interface TokenResponse {
  access_token: string;
  created_at: number;
  scope: string;
  token_type: string;
}

export interface SignInRequest {
  token: string;
  email: string;
  password: string;
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["User", "Posts"],
  baseQuery: fetchBaseQuery({
    // Uncomment this and remove the baseUrl from individual queries once the cors issues have been solved.
    // baseUrl: mastodonConfig.api,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getLocalStorage("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    account: builder.query<LoggedUser, void>({
      // Response, Request
      query: () => {
        return {
          url: mastodonConfig.api + "/api/v1/accounts/verify_credentials",
          method: "GET",
        };
      },
      providesTags: ["User"],
    }),
    getToken: builder.query({
      query: (token) => {
        const formData = new FormData();
        formData.append("client_id", mastodonConfig.clientKey as string);
        formData.append("client_secret", mastodonConfig.clientSecret as string);
        formData.append("redirect_uri", mastodonConfig.redirectUri as string);
        formData.append("grant_type", "authorization_code");
        formData.append("code", token);
        formData.append("scope", mastodonConfig.scopes as string);

        return {
          url: mastodonConfig.api + "/oauth/token",
          method: "POST",
          body: formData,
        };
      },
      transformResponse(apiResponse: TokenResponse, meta: any) {
        if (meta.response.status >= 200 && meta.response.status < 400) {
          setLocalStorage("token", apiResponse.access_token);
        }
        return apiResponse;
      },
    }),
    revokeToken: builder.query<any, void>({
      query: () => {
        const token = getLocalStorage("token");
        const formData = new FormData();
        formData.append("client_id", mastodonConfig.clientKey as string);
        formData.append("client_secret", mastodonConfig.clientSecret as string);
        formData.append("token", token);

        return {
          url: mastodonConfig.proxiedApi + "/oauth/revoke",
          method: "POST",
          body: formData,
        };
      },
      transformResponse(apiResponse: any, meta: any) {
        if (meta.response.status >= 200 && meta.response.status < 400) {
          setLocalStorage("token", "");
        }
        return apiResponse;
      },
    }),
    updateAccount: builder.mutation<User, Partial<User>>({
      // Response, Request
      query: (data) => {
        return {
          url: mastodonConfig.api + "/api/v1/accounts/update_credentials",
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["User"],
    }),
    timeline: builder.query<Post[], void>({
      // Response, Request
      query: () => {
        return {
          url: mastodonConfig.api + "/api/v1/timelines/public",
          method: "GET",
        };
      },
      providesTags: ["Posts"],
    }),
    // https://docs.joinmastodon.org/methods/statuses/#create
    createPost: builder.mutation<
      Post,
      { status: string; in_reply_to_id?: string }
    >({
      // Response, Request
      query: (data) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Posts"],
    }),
    getPostSource: builder.query<Context, string>({
      // Response, Request
      query: (id: string) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id + "/source",
          method: "GET",
        };
      },
    }),
    // https://docs.joinmastodon.org/methods/statuses/#delete
    editPost: builder.mutation<Post, { id: string; status: string }>({
      // Response, Request
      query: (params) => {
        const { id, ...data } = params;
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Posts"],
    }),
    // https://docs.joinmastodon.org/methods/statuses/#delete
    deletePost: builder.mutation<Post, string>({
      // Response, Request
      query: (id: string) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Posts"],
    }),
    // https://docs.joinmastodon.org/methods/statuses/#favourite
    favoritePost: builder.mutation<Post, string>({
      // Response, Request
      query: (id: string) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id + "/favourite",
          method: "POST",
        };
      },
      invalidatesTags: [],
    }),
    // https://docs.joinmastodon.org/methods/statuses/#unfavourite
    unfavoritePost: builder.mutation<Post, string>({
      // Response, Request
      query: (id: string) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id + "/unfavourite",
          method: "POST",
        };
      },
      invalidatesTags: [],
    }),
    // https://docs.joinmastodon.org/methods/statuses/#boost
    reblogPost: builder.mutation<Post, string>({
      // Response, Request
      query: (id: string) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id + "/reblog",
          method: "POST",
        };
      },
      invalidatesTags: [],
    }),
    // https://docs.joinmastodon.org/methods/statuses/#unreblog
    unreblogPost: builder.mutation<Post, string>({
      // Response, Request
      query: (id: string) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id + "/unreblog",
          method: "POST",
        };
      },
      invalidatesTags: [],
    }),
    // https://docs.joinmastodon.org/methods/statuses/#context
    getPostContext: builder.query<Context, string>({
      // Response, Request
      query: (id: string) => {
        return {
          url: mastodonConfig.api + "/api/v1/statuses/" + id + "/context",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useLazyGetTokenQuery,
  useAccountQuery,
  useLazyAccountQuery,
  useLazyRevokeTokenQuery,
  useUpdateAccountMutation,
  useTimelineQuery,
  useCreatePostMutation,
  useEditPostMutation,
  useGetPostSourceQuery,
  useLazyGetPostSourceQuery,
  useDeletePostMutation,
  useFavoritePostMutation,
  useUnfavoritePostMutation,
  useReblogPostMutation,
  useUnreblogPostMutation,
  useGetPostContextQuery,
  useLazyGetPostContextQuery,
} = api;

// account selectors
export const selectAccountResult = api.endpoints.account.select();
export const selectAccount = createSelector(
  selectAccountResult,
  (userResult) => userResult?.data
);

export const selectAccountFields = createSelector(
  selectAccount,
  (user) => user?.source?.fields
);

export const selectLinkListField = createSelector(
  selectAccountFields,
  (fields) =>
    fields?.find((field) => field.name === "linkList") ?? ({} as UserField)
);

export const selectPostsResult = api.endpoints.timeline.select();
export const selectPosts = createSelector(
  selectPostsResult,
  (postsResult) => postsResult?.data
);

export const selectPost = createSelector(
  [selectPosts, (_, id) => id],
  (posts, id) => posts?.find((p: Post) => p.id === id)
);
