import type { FC } from 'react';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Box, ButtonBase, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { AccountPopover } from './account-popover';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import { useAccountQuery } from 'src/slices/api.slice';

export const AccountButton: FC = () => {
  const { data: user, isLoading, isFetching, isError } = useAccountQuery();
  const popover = usePopover<HTMLButtonElement>();
  const { t } = useTranslation();

  return (
    <>
    <Stack
      direction="row"
      spacing={2}
      alignItems="center">
      <div>
        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1' }}>
          { user?.display_name }
        </Typography>
        <Typography variant="body2" color="neutral.600" sx={{ lineHeight: '1' }}>
          { t(tokens.account.away) }
        </Typography>
      </div>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
          <Avatar
            sx={{
              height: 32,
              width: 32
            }}
            src={user?.avatar}
          >
            <SvgIcon>
              <User01Icon />
            </SvgIcon>
          </Avatar>
        
      </Box>
      </Stack>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
