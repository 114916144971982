import { tokens } from "../tokens";
import { de as linkListDE } from "../../widgets/linklist/linklist.translations";
import { de as timelineDE } from "../../widgets/timeline/timeline.translations";
import { de as overviewBannerDE } from "../../sections/dashboard/overview/overview.translations";
export const de = {
  ...linkListDE,
  ...timelineDE,
  ...overviewBannerDE,
  // account translations
  [tokens.account.away]: "Abwesend",
  [tokens.common.languageChanged]: "Sprache geändert",
  // Navigation
  [tokens.nav.account]: "Konto",
  [tokens.nav.analytics]: "Läuft bei uns",
  [tokens.nav.auth]: "Authentifizierung",
  [tokens.nav.blog]: "Deine News",
  [tokens.nav.browse]: "Durchsuche",
  [tokens.nav.calendar]: "Kalender",
  [tokens.nav.chat]: "Plaudern",
  [tokens.nav.checkout]: "Auschecken",
  [tokens.nav.concepts]: "Konzepte",
  [tokens.nav.contact]: "Kontakt",
  [tokens.nav.course]: "Kurs",
  [tokens.nav.create]: "Schaffen",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customers]: "Kunden",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.details]: "Einzelheiten",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Bearbeiten",
  [tokens.nav.error]: "Fehler",
  [tokens.nav.feed]: "Füttern",
  [tokens.nav.fileManager]: "Dateimanager",
  [tokens.nav.finance]: "Finanzen",
  [tokens.nav.fleet]: "Flotte",
  [tokens.nav.forgotPassword]: "Passwort Vergessen",
  [tokens.nav.invoiceList]: "Rechnungen",
  [tokens.nav.jobList]: "Stellenangebote",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "Aufführen",
  [tokens.nav.login]: "Anmeldung",
  [tokens.nav.logistics]: "Logistik",
  [tokens.nav.mail]: "E-Mail",
  [tokens.nav.management]: "Verwaltung",
  [tokens.nav.orderList]: "Aufträge",
  [tokens.nav.overview]: "Überblick",
  [tokens.nav.pages]: "Seiten",
  [tokens.nav.postCreate]: "Beitrag erstellen",
  [tokens.nav.postDetails]: "Beitragsdetails",
  [tokens.nav.postList]: "Beitragsliste",
  [tokens.nav.pricing]: "Preisgestaltung",
  [tokens.nav.productList]: "Produkte",
  [tokens.nav.profile]: "Profil",
  [tokens.nav.settings]: "Einstellungen",
  [tokens.nav.register]: "Registrieren",
  [tokens.nav.resetPassword]: "Passwort Zurücksetzen",
  [tokens.nav.marketplace]: "Marktplatz",
  [tokens.nav.hr]: "Wir Fruchtweinmacher",
  [tokens.nav.organigram]: "Kolleg*Innenfinder",
  [tokens.nav.verifyCode]: "Code Überprüfen",
  [tokens.nav.knowledgebase]: "Wissenswert",
  [tokens.nav.myArea]: "Mein Bereich",
};
