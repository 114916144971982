import { SvgIcon } from "@mui/material";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import Building04Icon from "src/icons/untitled-ui/duocolor/building-04";
import Mail04Icon from "src/icons/untitled-ui/duocolor/mail-04";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LayoutAlt02Icon from "src/icons/untitled-ui/duocolor/layout-alt-02";
import Share07Icon from "src/icons/untitled-ui/duocolor/share-07";
import Users03 from "src/icons/untitled-ui/duocolor/users-03";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import File04 from "src/icons/untitled-ui/duocolor/file-04";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.analytics),
            path: paths.analytics,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.hr),
            path: paths.hr.index,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.hr),
                path: paths.hr.index,
                icon: (
                  <SvgIcon fontSize="small">
                    <Mail04Icon />
                  </SvgIcon>
                ),
              },
              {
                title: t(tokens.nav.contact),
                path: paths.hr.contact,
                icon: (
                  <SvgIcon fontSize="small">
                    <Mail04Icon />
                  </SvgIcon>
                ),
              },
              {
                title: t(tokens.nav.jobList),
                path: paths.hr.jobs.index,
                icon: (
                  <SvgIcon fontSize="small">
                    <Building04Icon />
                  </SvgIcon>
                ),
              },
              {
                title: t(tokens.nav.organigram),
                path: paths.hr.organigram,
                icon: (
                  <SvgIcon fontSize="small">
                    <Building04Icon />
                  </SvgIcon>
                ),
              },
            ],
          },
          {
            title: t(tokens.nav.blog),
            path: paths.blog.index,
            icon: (
              <SvgIcon fontSize="small">
                <LayoutAlt02Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.marketplace),
            path: paths.marketplace.index,
            icon: (
              <SvgIcon fontSize="small">
                <Share07Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.knowledgebase),
            path: paths.knowledgebase,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.myArea),
        items: [
          {
            title: t(tokens.nav.account),
            path: paths.account,
            icon: (
              <SvgIcon fontSize="small">
                <Users03 />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.settings),
            path: paths.settings,
            icon: (
              <SvgIcon fontSize="small">
                <File04 />
              </SvgIcon>
            ),
          },
        ],
      },
    ];
  }, [t]);
};
