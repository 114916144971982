import { lazy } from "react";
import { RouteObject } from "react-router";
const Blank = lazy(() => import('src/pages/blank'));

const routes: RouteObject[] = [
  {
    path: 'marketplace',
    element: <Blank />
  },
  // {
  //   path: 'marketplace/create',
  //   element: <Blank />
  // },
  // {
  //   path: 'marketplace/:postId',
  //   element: <Blank />
  // },
];

export default routes;