import { lazy } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { Layout as AuthLayout } from "src/layouts/auth/focused-layout";
import { Layout as AuthFocusedLayout } from "src/layouts/auth/focused-layout";
import { Layout as AuthSplitLayout } from "src/layouts/auth/split-layout";

const ForgotPasswordPage = lazy(
  () => import("src/pages/auth/forgot-password/forgot-password.page")
);
const LoginPage = lazy(() => import("src/pages/auth/login/login.page"));
const RegisterPage = lazy(
  () => import("src/pages/auth/register/register.page")
);
const ResetPasswordPage = lazy(
  () => import("src/pages/auth/reset-password/reset-password.page")
);
const VerifyCodePage = lazy(
  () => import("src/pages/auth/verify-code/verify-code.page")
);
const CallbackPage = lazy(
  () => import("src/pages/auth/callback/callback.page")
);

const routes: RouteObject[] = [
  {
    path: "auth",
    children: [
      {
        path: "forgot-password",
        element: (
          <AuthFocusedLayout>
            <ForgotPasswordPage />
          </AuthFocusedLayout>
        ),
      },
      {
        path: "login",
        element: (
          <AuthSplitLayout>
            <LoginPage />
          </AuthSplitLayout>
        ),
      },
      {
        path: "register",
        element: (
          <AuthSplitLayout>
            <RegisterPage />
          </AuthSplitLayout>
        ),
      },
      {
        path: "reset-password",
        element: (
          <AuthFocusedLayout>
            <ResetPasswordPage />
          </AuthFocusedLayout>
        ),
      },
      {
        path: "verify-code",
        element: (
          <AuthFocusedLayout>
            <VerifyCodePage />
          </AuthFocusedLayout>
        ),
      },
      {
        path: "callback",
        element: <CallbackPage />,
      },
    ],
  },
];

export default routes;
