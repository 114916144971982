export const tokens = {
  title: "title",
  delete: "delete",
  edit: "edit",
  dialog: {
    closeAria: "close",
  },
};

export const de = {
  [tokens.title]: "Feed",
  [tokens.delete]: "Entfernen",
  [tokens.edit]: "Bearbeiten",
  [tokens.dialog.closeAria]: "Schliessen",
};

export const en = {
  [tokens.title]: "Feed",
  [tokens.delete]: "Delete",
  [tokens.edit]: "Edit",
  [tokens.dialog.closeAria]: "Close",
};
