import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import type { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { RTL } from "./components/rtl";
import { SplashScreen } from "./components/splash-screen";
import { Toaster } from "./components/toaster";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./contexts/settings-context";
import { useNprogress } from "./hooks/use-nprogress";
import { routes } from "./routes";
import { store } from "./store";
import { createTheme } from "./theme";
// Think about these…
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "./locales/i18n";

export const App: FC = () => {
  useNprogress();

  const element = useRoutes(routes);

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <SettingsConsumer>
            {(settings) => {
              // Prevent theme flicker when restoring custom settings from browser storage
              if (!settings.initialized) {
                // return null;
              }

              const theme = createTheme({
                colorPreset: settings.colorPreset,
                contrast: settings.contrast,
                direction: settings.direction,
                paletteMode: settings.paletteMode,
                responsiveFontSizes: settings.responsiveFontSizes,
              });

              return (
                <ThemeProvider theme={theme}>
                  <Helmet>
                    <meta name="color-scheme" content={settings.paletteMode} />
                    <meta
                      name="theme-color"
                      content={theme.palette.neutral[900]}
                    />
                  </Helmet>
                  <RTL direction={settings.direction}>
                    <CssBaseline />
                    <>{element}</>
                    <Toaster />
                  </RTL>
                </ThemeProvider>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};
