import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UIState {
  postContextPostId: string | null;
  editPostId: string | null;
  editPostDialogOpen: boolean;
}

const initialState: UIState = {
  postContextPostId: null,
  editPostId: null,
  editPostDialogOpen: false,
};

export const UISlice = createSlice({
  name: "UIState",
  initialState,
  reducers: {
    togglePostReplies: (
      state,
      action: PayloadAction<{ postId: string | null }>
    ) => {
      state.postContextPostId = action.payload.postId;
    },
    toggleTimelineEditDialog: (
      state,
      action: PayloadAction<{ open: boolean; postId: string | null }>
    ) => {
      state.editPostId = action.payload.postId;
      state.editPostDialogOpen = action.payload.open;
    },
  },
});

export const { togglePostReplies, toggleTimelineEditDialog } = UISlice.actions;

export default UISlice.reducer;
