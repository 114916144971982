export const tokens = {
  title: "title",
  editButton: "edit",
  editAria: "editAria",
  dialog: {
    title: "dialog.title",
    closeAria: "dialog.closeAria",
    addButton: "dialog.addButton",
    deleteAria: "dialog.deleteAria",
    save: "dialog.save",
    cancel: "dialog.cancel",
  },
};

export const de = {
  [tokens.title]: "Deine meist benutzte Links:",
  [tokens.editButton]: "Links bearbeiten:",
  [tokens.editAria]: "Links bearbeiten",
  [tokens.dialog.title]: "Links hinzufügen, bearbeiten und entfernen:",
  [tokens.dialog.addButton]: "Link hinzufügen:",
  [tokens.dialog.deleteAria]: "Link entfernen",
  [tokens.dialog.closeAria]: "Dialog schließen",
  [tokens.dialog.save]: "Links sichern",
  [tokens.dialog.cancel]: "Abbrechen",
};

// expand this object with 10 entries more, please!
export const en = {
  [tokens.title]: "Your most used links:",
  [tokens.editButton]: "Edit links",
  [tokens.editAria]: "Edit links",
  [tokens.dialog.title]: "Edit, add and delete links",
  [tokens.dialog.addButton]: "Add link",
  [tokens.dialog.deleteAria]: "Delete link",
  [tokens.dialog.closeAria]: "Close dialog",
  [tokens.dialog.save]: "Save links",
  [tokens.dialog.cancel]: "Cancel",
};
