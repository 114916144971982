import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { useLazyAccountQuery } from "src/slices/api.slice";

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  const [trigger] = useLazyAccountQuery();

  async function checkServer() {
    await trigger().then((result) => {
      if (result.isError) {
        console.log("error", result);
        setChecked(false);
        window.location.href = paths.auth.login;
      } else {
        console.log("error", result);
        setChecked(true);
      }
    });
  }

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      const signInFetch = async () => {
        return checkServer();
      };
      signInFetch().catch(console.error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
