import { lazy } from "react";
import { RouteObject } from "react-router";
const ContactPage = lazy(() => import("src/pages/contact"));
const Blank = lazy(() => import("src/pages/blank"));
const Organigram = lazy(() => import("src/pages/organigram"));

const routes: RouteObject[] = [
  {
    path: "hr",
    element: <Blank />,
  },
  {
    path: "hr/contact",
    element: <ContactPage />,
  },
  {
    path: "hr/jobs",
    element: <Blank />,
  },
  {
    path: "hr/jobs/create",
    element: <Blank />,
  },
  {
    path: "hr/jobs/:jobId",
    element: <Blank />,
  },
  {
    path: "hr/organigram",
    element: <Organigram />,
  },
];

export default routes;
