export const paths = {
  index: "/",
  analytics: "/analytics",
  blank: "/blank",
  blog: {
    index: "/blog",
    postDetails: "/blog/:postId",
    postCreate: "/blog/create",
  },
  hr: {
    index: "hr",
    contact: "/hr/contact",
    jobs: {
      index: "/hr/jobs",
      create: "/hr/jobs/create",
      companies: {
        details: "/hr/jobs/companies/:companyId",
        create: "/hr/jobs/companies/create",
      },
    },
    organigram: "/hr/organigram",
  },
  marketplace: {
    index: "/marketplace",
    profile: "/marketplace/profile",
    feed: "/marketplace/feed",
  },
  contact: "/contact",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    forgotPassword: "auth/forgot-password",
    resetPassword: "auth/reset-password",
    verifyCode: "auth/verify-code",
    callback: "auth/callback",
  },
  knowledgebase: "/knowledgebase",
  account: "/account",
  settings: "/settings",
};
