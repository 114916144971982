export const amplifyConfig = {
  aws_project_region: process.env.KATNET_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.KATNET_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.KATNET_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.KATNET_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.KATNET_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  base_url: process.env.KATNET_AUTH0_BASE_URL,
  client_id: process.env.KATNET_AUTH0_CLIENT_ID,
  issuer_base_url: process.env.KATNET_AUTH0_ISSUER_BASE_URL
};

export const mastodonConfig = {
  url: process.env.REACT_APP_URL || '',
  api: process.env.REACT_APP_MASTODON_API || '',
  proxiedApi: process.env.REACT_APP_PROXIED_MASTODON_API || '',
  clientKey: process.env.REACT_APP_MASTODON_CLIENT_KEY || '',
  clientSecret: process.env.REACT_APP_MASTODON_CLIENT_SECRET || '',
  responseType: process.env.REACT_APP_MASTODON_RESPONSE_TYPE || '',
  redirectUri: process.env.REACT_APP_MASTODON_REDIRECT_URI || '',
  scopes: process.env.REACT_APP_MASTODON_SCOPES || '',
};

export const firebaseConfig = {
  apiKey: process.env.KATNET_FIREBASE_API_KEY,
  appId: process.env.KATNET_FIREBASE_APP_ID,
  authDomain: process.env.KATNET_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.KATNET_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.KATNET_FIREBASE_PROJECT_ID,
  storageBucket: process.env.KATNET_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.KATNET_GTM_CONTAINER_ID
};

export const mapboxConfig = {
  apiKey: process.env.KATNET_MAPBOX_API_KEY
};
