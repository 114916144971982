import { lazy } from "react";
import { RouteObject } from "react-router";
const Blank = lazy(() => import('src/pages/blank'));

const routes: RouteObject[] = [
  {
    path: 'blog',
    element: <Blank />
  },
  {
    path: 'blog/create',
    element: <Blank />
  },
  {
    path: 'blog/:postId',
    element: <Blank />
  },
];

export default routes;